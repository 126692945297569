import { Snackbar } from './snack';

export async function sha256(message: string) {
  // encode as UTF-8
  const msgBuffer = new TextEncoder().encode(message);
  const check = crypto?.subtle?.digest;
  if (check === null || check === undefined) {
    console.error('Crypto API not available. Are we running over HTTPS?');
    Snackbar.setMsg('device-unsupported', 'Device not supported');
    return null;
  }

  // hash the message
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

  // convert ArrayBuffer to Array
  const hashArray = Array.from(new Uint8Array(hashBuffer));

  // convert bytes to hex string
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
  return hashHex;
}
