import { Translator } from './i18n';
import { templateById } from './template';

export class Form {
  public constructor(private readonly element: HTMLElement | ParentNode | null) {}

  public async translate() {
    const form = this.element?.querySelector('form');
    for (const element of form?.elements ?? []) {
      const name = element.getAttribute('name');
      if (!name) {
        continue;
      }

      const translation = await Translator.translateKey(name);
      if (!translation || translation === name) {
        continue;
      }

      element.setAttribute('placeholder', translation);
    }
    return this.element;
  }
}

export class TemplatedForm extends Form {
  public static fromTemplateId(templateId: string) {
    const template = templateById(templateId);
    return new this(template);
  }

  private static templateToParentNode(template: HTMLTemplateElement | null) {
    if (template === null) {
      return null;
    }
    return document.importNode(template.content, true);
  }

  public constructor(readonly template: HTMLTemplateElement | null) {
    super(TemplatedForm.templateToParentNode(template));
  }
}
