import 'normalize.css';
import 'font-awesome/css/font-awesome.css';
import './styles/style.scss';

import { events } from './events';
import { Form } from './form';
import { CalendarRenderer } from './calendar';
import { hookup } from './send';

async function instantiateForm() {
  const container = document.getElementById('rsvp-container');
  const form = new Form(container);
  await form.translate();
}

function showEvents() {
  const eventsContainer = document.getElementById('events');
  if (eventsContainer === null) {
    return;
  }
  const renderer = CalendarRenderer.fromIds('calendar-collection', 'calendar-entry');
  if (renderer === null) {
    return;
  }
  const render = renderer.render(events);
  if (render === null) {
    return;
  }
  eventsContainer.appendChild(render);
}

async function bootstrap() {
  await instantiateForm();
  showEvents();
  hookup();
}

bootstrap().then(console.log).catch(console.error);
