import type { CalendarEvent } from 'calendar-link';
import { EventTimeBuilder } from './temporal';

const builder = new EventTimeBuilder({
  year: 2023,
  month: 10,
  day: 14,
  timeZone: 'Europe/Berlin',
});

export type Event = CalendarEvent & { id: string };

export const firstNightHotel: Event = {
  id: 'hotel1',
  title: 'Hotel accommodation Fri -> Sat',
  description: 'Specific hotels/rooms to be announced',
  start: builder.from({ hour: 15, minute: 0, day: 13 }),
  end: builder.from({ hour: 10, minute: 0 }),
  location: 'Kirchheim unter Teck, Germany',
};

export const secondNightHotel: Event = {
  id: 'hotel2',
  title: 'Hotel accommodation Sat -> Sun',
  description: 'Specific hotels/rooms to be announced',
  start: builder.from({ hour: 15, minute: 0 }),
  end: builder.from({ hour: 10, minute: 0, day: 15 }),
  location: 'Kirchheim unter Teck, Germany',
};

export const cityTourDutch: Event = {
  id: 'tour_dutch',
  title: 'Nederlandstalige City Tour Kirchheim unter Teck',
  description: 'De City Tour heeft slechts beperkte capaciteit.',
  start: builder.from({ hour: 10, minute: 0 }),
  duration: [1, 'hour'],
  location: 'Kirchheim unter Teck, Germany',
};

export const cityTourGerman: Event = {
  id: 'tour_german',
  title: 'Deutschsprachige City Tour Kirchheim unter Teck',
  start: builder.from({ hour: 10, minute: 0 }),
  duration: [1, 'hour'],
  location: 'Kirchheim unter Teck, Germany',
};

export const ceremony: Event = {
  id: 'ceremony',
  title: 'Church ceremony Teresa & Jelle',
  start: builder.from({ hour: 15, minute: 30 }),
  end: builder.from({ hour: 16, minute: 30 }),
  location: 'Christuskirche 73230 Kirchheim unter Teck, Germany',
};

export const reception: Event = {
  id: 'reception',
  title: 'Wedding Reception Teresa & Jelle',
  description: 'Socialising, dining and enjoying the evening',
  start: builder.from({ hour: 17, minute: 0 }),
  end: builder.from({ hour: 23, minute: 59 }),
  location: 'Alleenstrasse 116, 73230 Kirchheim unter Teck, Germany',
};

export const events = [
  ceremony,
  reception,
  firstNightHotel,
  secondNightHotel,
  cityTourDutch,
  cityTourGerman,
];
