import type { Event } from './events';
import { google, ics } from 'calendar-link';
import { templateById } from './template';

export class CalendarRenderer {
  public static fromIds(collectionId: string, entryId: string) {
    const collection = templateById(collectionId);
    const entry = templateById(entryId);
    const isValid = collection !== null && entry !== null;
    if (isValid) {
      return new this(collection, entry);
    }
    return null;
  }

  public constructor(
    private readonly collectionTemplate: HTMLTemplateElement,
    private readonly entryTemplate: HTMLTemplateElement
  ) {}

  public render(events: Event[]): ParentNode | null {
    const node = document.importNode(this.collectionTemplate.content, true);
    const container = node.querySelector('.event-entries');
    if (container === null) {
      return null;
    }
    for (const event of events) {
      container.appendChild(this.renderEntry(event));
    }
    return node;
  }

  private renderEntry(event: Event): ParentNode {
    const node = document.importNode(this.entryTemplate.content, true);

    const titleElement = node.querySelector('.event-title');
    if (titleElement !== null) {
      titleElement.textContent = event.title;
    }

    const descriptionElement = node.querySelector('.event-description');
    if (descriptionElement !== null) {
      descriptionElement.textContent = event.description ?? null;
    }

    const calendarAnchor: HTMLAnchorElement | null = node.querySelector('a.cal-link');
    calendarAnchor?.setAttribute('href', ics(event));

    const googleAnchor: HTMLAnchorElement | null = node.querySelector('a.google-link');
    googleAnchor?.setAttribute('href', google(event));

    return node;
  }
}
