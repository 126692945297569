import { Translator } from './i18n';

export class Snackbar extends null {
  private static readonly TIMEOUT = 5_000;
  private static readonly ID = 'snackbar';
  static readonly #bite = document.getElementById(this.ID);
  static #fader: null | Promise<void> = null;

  private static reset = () => {
    if (this.#bite) {
      this.#bite.className = this.#bite.className.replace('show', '');
    }
  };

  private static set = (text: string) => {
    if (this.#bite) {
      this.#bite.textContent = text;
      this.#bite.className = 'show';
    }
  };

  static async setMsg(key: string, defaultMsg: string) {
    await this.#fader;
    this.#fader = new Promise((resolve, _reject) =>
      setTimeout(() => {
        this.reset();
        resolve();
      }, this.TIMEOUT)
    );
    let translation = await Translator.translateKey(key);
    // TODO: cleaner API?
    if (translation === key) {
      translation = defaultMsg;
    }
    this.set(translation);
  }
}
