//import { Temporal } from '@js-temporal/polyfill';
import { Temporal } from 'temporal-polyfill';

export function dateWithTimeZone(item: Temporal.ZonedDateTime | Temporal.ZonedDateTimeLike): Date {
  const zonedDateTime = Temporal.ZonedDateTime.from(item);
  const instant = zonedDateTime.toInstant();
  return new Date(instant.toString());
}

export class EventTimeBuilder {
  constructor(
    private readonly options: {
      year: number;
      month: number;
      day: number;
      timeZone: string;
    }
  ) {}
  public from<T extends { hour: number; minute: number }>(data: T): Date {
    return dateWithTimeZone({ ...this.options, ...data });
  }
}
