import { sha256 } from './hash';
import { Modal } from './modal';
import { Snackbar } from './snack';

// Hash of the super duper secret key
const keyHash = '28a239dcd7517f8997b0230b8ca09d6bad1cdb1c5d093d1bcc8a6454f48961f0';

const API =
  'https://script.google.com/macros/s/AKfycbwK_Rj_8alEmZ-Zy2iNx0SF1CWfoTtiBKl8DYtkjie8oLPkOFPyM3GXFvLa0qpCOBOtTQ/exec';

function assertIsString(value: unknown): asserts value is string {
  if (typeof value !== 'string') throw Error('value is not a string');
}

function send(data: FormData) {
  return fetch(API, {
    method: 'POST',
    body: data,
  });
}

async function submit(this: HTMLFormElement, e: SubmitEvent) {
  e.preventDefault();
  const formData = new FormData(this);
  const invite_code = formData.get('invite_code');
  assertIsString(invite_code);
  // Why in tarnation is this so silly
  const checksum = await sha256(invite_code);
  if (checksum === null) {
    console.error('Hashes is null');
    return;
  }
  if (checksum !== keyHash) {
    Snackbar.setMsg('wrong_invite', 'Invite code is incorrect');
    console.error('Hashes do not match');
    return;
  }
  try {
    Modal.open();
    const response = await send(formData);
    Snackbar.setMsg('response_saved', 'Response saved! Thanks!');
  } catch (e: unknown) {
    console.error(`Error was: ${e}`);
    Snackbar.setMsg('unexpected_error', 'Something unexpected happened');
  } finally {
    Modal.close();
  }
}

export function hookup() {
  const rsvp: HTMLFormElement | null = document.querySelector('form#rsvp-form');
  rsvp?.addEventListener('submit', submit);
}
