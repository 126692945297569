import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './translations/en.json';
import de from './translations/de.json';
import nl from './translations/nl.json';

type TranlationFn = (key: string) => string;

export class Translator extends null {
  static #translator: Promise<TranlationFn>;

  private static _getTranslator(): Promise<TranlationFn> {
    if (!i18next.isInitialized) {
      return new Promise((resolve, _reject) => {
        i18next.on('initialized', (_) => {
          resolve(this.#translator);
        });
      });
    } else {
      return this.#translator;
    }
  }

  static async translateKey(key: string): Promise<string> {
    const translator = await this.#translator; //this.getTranslator();
    const msg = translator(key);
    return msg;
  }

  static {
    this.#translator = i18next.use(LanguageDetector).init({
      debug: true,
      fallbackLng: 'en',
      supportedLngs: ['en', 'nl', 'de'],
      nonExplicitSupportedLngs: true,
      resources: {
        en: { translation: en },
        de: { translation: de },
        nl: { translation: nl },
      },
    });
  }
}
